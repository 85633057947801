import ReactDOMServer from 'react-dom/server';

const getMapIcon = (layerId, color = null, toString = false) => {
    const defaultColor = 'DimGrey';
    let icon;

    switch (layerId) {
        case 'poles1':
            icon = (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                <line x1="10" y1="5" x2="10" y2="15" stroke={color || defaultColor} strokeWidth="2" />
                <line x1="5" y1="10" x2="15" y2="10" stroke={color || defaultColor} strokeWidth="2" />
            </svg>);
            break;
        case 'poles2':
            icon = (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                <polygon points="10,2 18,18 2,18" stroke={color || defaultColor} strokeWidth="2" fill="white" />
            </svg>);
            break;
        case 'poles3':
            icon = (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                <circle cx="10" cy="10" r="8" stroke={color || 'white'} strokeWidth="2" fill={color || defaultColor} />
            </svg>);
            break;
        case 'panoImagery2':
            icon = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="25" height="25">
                <rect x="4" y="6.25" width="17" height="12.5" rx="2" ry="2" stroke="LightSkyBlue" strokeWidth="1.5" fill={color || defaultColor} />
                <circle cx="12.5" cy="12.5" r="3.75" stroke="LightSkyBlue" strokeWidth="1.5" fill={color || defaultColor} />
                <rect x="1.25" y="10" width="5" height="5" stroke="LightSkyBlue" strokeWidth="1.5" fill={color || defaultColor} />
            </svg>);
            break;
        case 'panoImagery':
            icon = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="25" height="25">
                <circle cx="12.5" cy="12.5" r="3.75" stroke="LightSkyBlue" strokeWidth="0.5" fill={color || defaultColor} />
            </svg>);
            break;
        case 'poiImagery':
            icon = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="25" height="25">
                <rect x="4" y="6.25" width="17" height="12.5" rx="2" ry="2" stroke="LightSkyBlue" strokeWidth="1.5" fill={color || defaultColor} />
                <circle cx="12.5" cy="12.5" r="3.75" stroke="LightSkyBlue" strokeWidth="1.5" fill={color || defaultColor} />
                <rect x="1.25" y="10" width="5" height="5" stroke="LightSkyBlue" strokeWidth="1.5" fill={color || defaultColor} />
            </svg>);
            break;
        default:
            icon = null;
    }

    // Convert the React component to a static HTML string if toString is true
    if (toString && icon !== null) {
        icon = ReactDOMServer.renderToStaticMarkup(icon);
    }

    return icon;
};

export {
    getMapIcon
}